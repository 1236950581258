$primary: #82aad6;
$font-family-base: 'Cormorant Garamond', serif;
$headings-font-family: 'Playfair Display', serif;

@import '~bootstrap/scss/bootstrap.scss';

@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond|Playfair+Display');

h3, h2 {
  position: relative;
}

@include media-breakpoint-up(sm) {
  h2:after, h2:before, h3:before {
    content: '~';
    position: absolute;
    right: 100%;
    margin-right: .2em;
    opacity: .2;
  }

  h2:before {
    margin-top: -.15em;
  }

  h2:after {
    margin-top: .05em;
  }
}